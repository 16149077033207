import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.tobianoapps.website.pages.LandingPageLayout() }
        ctx.router.register("/android/sunnyside/faq") { com.tobianoapps.website.pages.SunnySideFAQ()
                }
        ctx.router.register("/android/sunnyside/legal/privacy") {
                com.tobianoapps.website.pages.android.sunnyside.legal.PrivacyPage() }
        ctx.router.register("/android/sunnyside/legal/terms") {
                com.tobianoapps.website.pages.android.sunnyside.legal.TermsPage() }
        ctx.router.register("/android/timerise/legal/privacy") {
                com.tobianoapps.website.pages.android.timerise.legal.PrivacyPage() }
        ctx.router.register("/android/timerise/legal/terms") {
                com.tobianoapps.website.pages.android.timerise.legal.TermsPage() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.DISALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("tba-ccs-doc-body",
                com.tobianoapps.website.components.layout.DocBodyStyle)
        ctx.theme.registerStyle("collapsible",
                com.tobianoapps.website.components.sections.faq.CollapsibleStyle)
        ctx.theme.registerStyle("tba-footer-footer",
                com.tobianoapps.website.components.sections.footer.FooterStyle)
        ctx.theme.registerStyle("tba-copyright-copyright",
                com.tobianoapps.website.components.sections.footer.CopyrightStyle)
        ctx.theme.registerStyle("tba-credits-credits",
                com.tobianoapps.website.components.sections.footer.CreditsStyle)
        ctx.theme.registerStyle("footer-social",
                com.tobianoapps.website.components.sections.footer.FooterSocialStyle)
        ctx.theme.registerStyle("tba-header-header",
                com.tobianoapps.website.components.sections.header.HeaderStyle)
        ctx.theme.registerStyle("theme-switch",
                com.tobianoapps.website.components.sections.header.ThemeSwitchStyle)
        ctx.theme.registerStyle("store-badge",
                com.tobianoapps.website.components.sections.project.StoreBadgeStyle)
        ctx.theme.registerStyle("tba-ccs-center-column",
                com.tobianoapps.website.pages.CenterColumnStyle)
        com.tobianoapps.website.initSilk(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Tobiano Apps"))
    renderComposable(rootElementId = "root") {
        com.tobianoapps.website.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
